<template>
    <div class="auth-wrapper">
        <div class="eister-modal">
            <div class="eister-modal__header">
                <span
                    v-if="!checkIsLoginPage"
                    class="eister-modal__header-arrow"
                    @click="$router.push('/login')"
                >
                    <arrow />
                </span>
                <div
                    v-if="checkIsLoginPage"
                    class="eister-modal__header-image"
                >
                    <slot name="image" />
                </div>
                <slot name="header" />
            </div>
            <div class="eister-modal__body">
                <slot name="body" />
            </div>
        </div>
    </div>
</template>

<script>
import Arrow from '@/icons/Arrow'

export default {
    name: 'AuthLayout',
    components: {
        Arrow
    },
    computed: {
        checkIsLoginPage() {
            return this.$route.name === 'login'
        }
    }
}
</script>

<style lang="scss">
    @import "@/assets/styles/dashboard/components/auth";
</style>
