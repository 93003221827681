<template>
    <auth-layout>
        <img
            slot="image"
            class="eister-modal__header-image"
            :src="`/img/logos/${getterLogo}`"
        >
        <h2
            slot="header"
            class="eister-modal__header-text"
        >
            Administrator Login
        </h2>
        <form
            slot="body"
            @submit.prevent="validate"
        >
            <div class="eister-modal__input-mask">
                <div class="input-field">
                    <form-input
                        ref="email"
                        v-model="$v.email.$model"
                        :field.sync="email"
                        name="email"
                        legend="Email address"
                        prepend-icon="EmailOutlineIcon"
                        type="text"
                        :errors="validationMessage($v.email)"
                        :is-valid="$v.email.$dirty && !$v.email.$anyError"
                        @input.native="$v.email.$touch()"
                        @blur.native="$v.email.$touch()"
                        @onChange="loadEmail = true"
                    />
                </div>

                <div class="input-field">
                    <form-input
                        v-model="$v.password.model"
                        :field.sync="password"
                        name="password"
                        legend="Password"
                        prepend-icon="LockIcon"
                        :append-icon="showPassword ? 'EyeIcon' : 'EyeOffIcon'"
                        :type="showPassword ? 'text' : 'password'"
                        :errors="validationMessage($v.password)"
                        :is-valid="$v.password.$dirty && !$v.password.$anyError"
                        @clicked="showPassword = !showPassword"
                        @input.native="$v.password.$touch()"
                        @blur.native="$v.password.$touch()"
                    />
                </div>

                <div class="input-field remember-me">
                    Forgot your password?
                    <router-link
                        to="/forgot-password"
                        class="forgot-password"
                    >
                        Reset
                    </router-link>
                    or
                    <router-link
                        to="/register"
                        class="forgot-password"
                    >
                        Register Account
                    </router-link>
                </div>

                <div class="input-field remember-me">
                    <form-checkbox
                        v-model="remember_me"
                        label="Remember me on this computer"
                    />
                </div>

                <transition name="slide-fade">
                    <div
                        v-if="error"
                        class="eister-alert eister-alert-danger"
                    >
                        {{ message }}
                        <i class="far fa-exclamation-triangle" />
                    </div>
                </transition>
            </div>

            <custom-button
                default
                class="button btn-base_colored"
                :loading="loading"
                :type="'submit'"
                width="100%"
            >
                Sign in
            </custom-button>
        </form>
    </auth-layout>
</template>

<script>
import AuthLayout from '@/layouts/AuthLayout';
import { eventBus } from '@/helpers/event-bus'

// Validation
import formValidationMixin from '@/mixins/formValidationMixin.js'
import { validationMixin } from 'vuelidate';
import { validationMessage } from 'vuelidate-messages'
import { validationRules } from '@/validation/Login/Rules'
import { formMessages } from '@/validation/Login/Messages'

import {
    ACTION_LOGIN_ADMIN
} from '@/store/modules/auth/action-types';
import { createNamespacedHelpers } from 'vuex';

const {
    mapActions: mapAdminActions
} = createNamespacedHelpers('auth');

export default {
    name: 'IndexVue',
    components: {
        AuthLayout
    },
    mixins: [
        validationMixin,
        formValidationMixin
    ],
    validations: {
        ...validationRules
    },
    data() {
        return {
            email: '',
            password: '',
            remember_me: false,
            showPassword: false,
            loading: false,
            error: null
        }
    },
    computed: {
        redirect() {
            return this.$route.query.redirect
        },
        getterLogo() {
            return `${process.env.VUE_APP_DOMAIN}.svg`
        }
    },
    mounted() {
        this.$refs.email.focus()
        if (this.email && this.password) {
            this.$v.$touch()
        }
    },
    methods: {
        ...mapAdminActions({
            loginAdmin: ACTION_LOGIN_ADMIN
        }),
        validationMessage: validationMessage(formMessages),
        async submit() {
            try {
                this.loading = true
                await this.loginAdmin({
                    email: this.email,
                    password: this.password
                })
                if (this.redirect) {
                    this.$router.push(this.redirect)
                }
                const previous_url = window.localStorage.getItem('previous_url');
                window.localStorage.removeItem('previous_url');
                if (previous_url) {
                    this.$router.push({ path: previous_url })
                } else {
                    this.$router.push({ name: 'posts-list-landing' })
                }
            } catch (e) {
                eventBus.$emit('showSnackBar', e.response.data.message, 'error');
                console.error(e)
            } finally {
                this.loading = false
            }
        }
    }
}
</script>

<style lang="scss">
.forgot-password {
    color: $orange;
}
</style>
